import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import VueLazyload from 'vue-lazyload'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import router from './router'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { firestorePlugin } from 'vuefire'
import Vue2TouchEvents from 'vue2-touch-events'
import { vfmPlugin } from 'vue-final-modal'
import vuetify from '@/plugins/vuetify'
import VueCookies from 'vue-cookies'
import './registerServiceWorker'
import VueHead from 'vue-head'
import analytics from './analytics'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import Ads from 'vue-google-adsense'

window.$ = window.jQuery = require('jquery')

Vue.use(require('vue-script2'))

Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)

Vue.use(VueLazyload, {
  preLoad: 2.6,
  lazyComponent: true,
  observer: true,
  observerOptions: {
    rootMargin: '0px',
    threshold: 0.1
  }
})

Vue.use(VueLodash, { lodash })

Vue.use(firestorePlugin)

Vue.use(Vue2TouchEvents)

Vue.use(vfmPlugin)

Vue.use(VueCookies)

Vue.use(VueHead, {
  separator: '',
  complement: ''
})

Vue.config.productionTip = false

Vue.component('v-select', vSelect)

const hideDuringScrolling = () => {
  $('footer').stop()
  $('footer').css('display', 'none').delay(500).fadeIn('fast')
}

router.beforeEach((to, from, next) => {
  console.log('beforeEach')

  if (to.meta.canonical) {
    const canonical = typeof to.meta.canonical === 'function' ? to.meta.canonical(to) : to.meta.canonical
    const head = document.head
    head.querySelectorAll('link[rel="canonical"]').forEach(link => link.remove())
    const link = document.createElement('link')
    link.rel = 'canonical'
    link.href = canonical
    head.appendChild(link)
  }

  if (to.meta.noindex) {
    const head = document.head
    head.querySelectorAll('meta[name="robots"]').forEach(meta => meta.remove())
    const meta = document.createElement('meta')
    meta.name = 'robots'
    meta.content = 'noindex'
    head.appendChild(meta)
  }

  analytics.logEvent('page_view', { type: 'internal' })
  document.getElementById('app').removeEventListener('scroll', hideDuringScrolling)
  document.getElementById('app').removeEventListener('touchmove', hideDuringScrolling)
  window.removeEventListener('scroll', hideDuringScrolling)
  window.removeEventListener('touchmove', hideDuringScrolling)
  next()
})

router.afterEach((to, from) => {
  console.log('afterEach')
  setTimeout(() => {
    document.getElementById('app').addEventListener('scroll', hideDuringScrolling)
    document.getElementById('app').addEventListener('touchmove', hideDuringScrolling)
    window.addEventListener('scroll', hideDuringScrolling)
    window.addEventListener('touchmove', hideDuringScrolling)
  }, 200)
})

if (process.env.VUE_APP_ENVIRONMENT === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://00ea114392914220ae6bab719215fdb8@o1103046.ingest.sentry.io/6129720',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'webzabe.rocks', /^\//]
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // tracesSampleRate: process.env.VUE_APP_ENVIRONMENT === 'production' ? 0.25 : 1
    tracesSampleRate: process.env.VUE_APP_ENVIRONMENT === 'production' ? 1 : 1
  })
}

const GlobalData = new Vue({
  data: {
    $downloadingVideos: [],
    $downloadSuccessVideos: [],
    $downloadFailedVideos: [],
    $alreadyDownloadingVideos: [],
    $alreadyDownloadedVideos: [],
    $downloadAlertZIndex: 999
  }
})
Vue.mixin({
  methods: {
    $_downloadingVideos () {
      return GlobalData.$data.$downloadingVideos
    },
    $_setDownloadingVideos (newDownloadingVideos) {
      GlobalData.$data.$downloadingVideos = newDownloadingVideos
    },
    $_downloadSuccessVideos () {
      return GlobalData.$data.$downloadSuccessVideos
    },
    $_setDownloadSuccessVideos (newDownloadSuccessVideos) {
      GlobalData.$data.$downloadSuccessVideos = newDownloadSuccessVideos
    },
    $_downloadFailedVideos () {
      return GlobalData.$data.$downloadFailedVideos
    },
    $_setDownloadFailedVideos (newDownloadFailedVideos) {
      GlobalData.$data.$downloadFailedVideos = newDownloadFailedVideos
    },
    $_alreadyDownloadingVideos () {
      return GlobalData.$data.$alreadyDownloadingVideos
    },
    $_setAlreadyDownloadingVideos (newAlreadyDownloadingVideos) {
      GlobalData.$data.$alreadyDownloadingVideos = newAlreadyDownloadingVideos
    },
    $_alreadyDownloadedVideos () {
      return GlobalData.$data.$alreadyDownloadedVideos
    },
    $_setAlreadyDownloadedVideos (newAlreadyDownloadedVideos) {
      GlobalData.$data.$alreadyDownloadedVideos = newAlreadyDownloadedVideos
    },
    $_downloadAlertZIndex () {
      return GlobalData.$data.$downloadAlertZIndex
    },
    $_setDownloadAlertZIndex (newDownloadAlertZIndex) {
      GlobalData.$data.$downloadAlertZIndex = newDownloadAlertZIndex
    }
  },
  computed: {
    $downloadingVideos: {
      get: function () {
        return GlobalData.$data.$downloadingVideos
      },
      set: function (newDownloadingVideos) {
        GlobalData.$data.$downloadingVideos = newDownloadingVideos
      }
    },
    $downloadSuccessVideos: {
      get: function () {
        return GlobalData.$data.$downloadSuccessVideos
      },
      set: function (newDownloadSuccessVideos) {
        GlobalData.$data.$downloadSuccessVideos = newDownloadSuccessVideos
      }
    },
    $downloadFailedVideos: {
      get: function () {
        return GlobalData.$data.$downloadFailedVideos
      },
      set: function (newDownloadFailedVideos) {
        GlobalData.$data.$downloadFailedVideos = newDownloadFailedVideos
      }
    },
    $alreadyDownloadingVideos: {
      get: function () {
        return GlobalData.$data.$alreadyDownloadingVideos
      },
      set: function (newAlreadyDownloadingVideos) {
        GlobalData.$data.$alreadyDownloadingVideos = newAlreadyDownloadingVideos
      }
    },
    $alreadyDownloadedVideos: {
      get: function () {
        return GlobalData.$data.$alreadyDownloadedVideos
      },
      set: function (newAlreadyDownloadedVideos) {
        GlobalData.$data.$alreadyDownloadedVideos = newAlreadyDownloadedVideos
      }
    },
    $downloadAlertZIndex: {
      get: function () {
        return GlobalData.$data.$downloadAlertZIndex
      },
      set: function (newDownloadAlertZIndex) {
        GlobalData.$data.$downloadAlertZIndex = newDownloadAlertZIndex
      }
    }
  }
})

new Vue({
  router,
  render: h => h(App),
  vuetify
}).$mount('#app')
