<template>
  <div data-vuetify>
    <v-app>
      <v-footer
        v-bind="localAttrs"
        :padless="padless"
        color="#4DBA87"
      >
        <v-row
          justify="center"
          no-gutters
        >
          <v-card
            v-if="icons.length > 0"
            flat
            tile
            width="100%"
            class="white lighten-1 text-center"
          >
            <v-card-text>
              <v-btn
                v-for="icon in icons"
                :key="icon.icon"
                class="mx-4"
                icon
                :to="icon.to"
              >
                <v-badge color="red" :content="downloadingVideos.length" v-model="downloadingVideos.length" v-if="icon.icon === 'mdi-tray-arrow-down'">
                  <v-icon size="24px">
                    {{ icon.icon }}
                  </v-icon>
                </v-badge>
                <v-icon size="24px" v-else>
                  {{ icon.icon }}
                </v-icon>
              </v-btn>
            </v-card-text>
          </v-card>
          <v-btn
            v-for="link in links"
            :key="link.text"
            color="white"
            text
            rounded
            :to="link.to"
            :class="$vuetify.breakpoint.name === 'xs' ? 'px-2' : ''"
          >
            {{ link.text }}
          </v-btn>
          <v-col
            class="pb-1 text-center white--text"
            cols="12"
          >
            {{ 2021 }} — <strong>ウェブザベ!</strong>
          </v-col>
        </v-row>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
import dao from '../dao'

export default {
  data: () => ({
    links: [
      {
        text: 'このアプリについて',
        to: { name: 'about' }
      },
      {
        text: '利用規約',
        to: { name: 'terms' }
      },
      {
        text: 'プライバシーポリシー',
        to: { name: 'privacy' }
      },
      {
        text: 'お問い合わせ',
        to: { name: 'contact' }
      }
    ],
    icons: [
      // { icon: 'mdi-magnify', to: { name: 'explore' } },
      // { icon: 'mdi-tray-arrow-down', to: { name: 'downloaded' } }
    ],
    items: [
      'default',
      'absolute',
      'fixed'
    ],
    padless: true,
    variant: 'fixed',
    downloadingVideos: []
  }),
  computed: {
    localAttrs () {
      const attrs = {}

      if (this.variant === 'default') {
        attrs.absolute = false
        attrs.fixed = false
      } else {
        attrs[this.variant] = true
      }
      return attrs
    }
  },
  created () {
    dao.findAllDownloadingVideos().then(videos => {
      this.downloadingVideos = videos
    })
  }
}
</script>
